import moment from "moment";
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  IconButton,
  Checkbox
} from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    width: '80%',
    overflowX: 'auto',
    margin: '0 auto',
    marginTop: '50px',
  },

  counts: {
    fontWeight: 'bold',
    textAlign: 'center',
  }
});

function LeadsByDate({ data, startDate, endDate, calendarOpen, leadsDisabled, handleLeadsByDateCheck }) {
    const [ modalData, setModalData ] = useState(null);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ totalCounts, setTotalCounts ] = useState(0);
    const classes = useStyles();

    async function fetchData() {
      const result = await axios.get(`/buffer/statistic/lead-counts/by-call-count?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`);
      
      setModalData(result?.data);
    }

    useEffect(() => {
        fetchData();

      }, [startDate, endDate]);
    
    useEffect(() => {
        let total = 0;
        for (let lead in modalData) {
            total += modalData[lead];
        }

        setTotalCounts(total);
    });

      if (data?.length === 0) {
        return <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', fontSize: '18px', margin: '20px' }}>No leads to display.</div>;
      }

      if (calendarOpen) {
        return <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', fontSize: '18px', margin: '20px' }}></div>;
      }
      
    const handleClickRow = () => {
      setModalOpen(true);
    }
    
    const calculatePercentage = (part) => {

        if (isNaN(part) || isNaN(totalCounts) || totalCounts === 0 || !isFinite(part) || !isFinite(totalCounts)) return "";
            
        let percentage = (part / totalCounts) * 100;

        
        return percentage.toFixed(2);
    }

    const counts = data.map((item) => item.count);
    const maxCount = Math.max(...counts);
    const minHeight = 20;
    const today = new Date();
    const todayString = today.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
    
    return (
      <div style={{ width: "80%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: '0 auto' }}>
        <TableContainer component={Paper}  style={{ maxHeight: "100%", maxWidth: "100%", overflow: "auto", marginTop: '100px', marginBottom: '100px' }}>
          <Table className={classes.table} aria-label="leads by date table">
            <TableHead onClick={handleClickRow}>
              {data.map(({ count, date }, index) => {

                let height;
                if (count > 0) {
                  height = (count / maxCount) * 200;

                  if (height < 20) {
                    height += 20
                  }
                }
                
                const backgroundColor = count > 0 ? `rgb(112, 225, 97, ${count / maxCount < 0.6 ? 0.6+count/maxCount : count / maxCount})` : 'transparent';


                return (
                  <TableCell key={index} style={{ borderBottom: 'none' }}>
                    {count >= 0 && !leadsDisabled.by_date[date] && (
                      <div
                        style={{
                          minHeight: '110px',
                          height: `${height}px`,
                          backgroundColor,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {count > 0 ? count : 0}
                      </div>
                    )}
                  </TableCell>
                );
              })}
          </TableHead>
            <TableBody>
              <TableRow>
                {data.map(({ date, weekday }) => (
                  <TableCell key={date}  className={classes.tableContent} style={{ borderBottom: 'none' }}>
                    {date === todayString ? (
                      <div>Today</div>
                    ) : (
                      <div>{weekday.slice(0, 3)}</div>
                    )}
                    <div>{date}</div>
                    <Checkbox onChange={(e) => handleLeadsByDateCheck(e, date)} checked={leadsDisabled.by_date.hasOwnProperty(date) ? false : true} style={{color: '#07b839'}}/>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 999999
            }}
            >
            <div
                style={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                width: '60vw',
                height: '60vh',
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                position: 'relative',
                }}
            >
                <IconButton
                    onClick={() => setModalOpen(false)}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        backgroundColor: '#EFF2C0',
                        borderRadius: '50%',
                        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                        zIndex: 100000000
                    }}
                >
                <CloseIcon />
                </IconButton>

                <div style={{ flex: '1', overflow: 'auto' }}>
                    {modalData && (
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {Object.keys(modalData).map((key) => (
                                            <TableCell align="center">{`R:${key}`}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {Object.values(modalData).map((value) => (
                                            <TableCell align="center">{`L:${value}`}</TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        {Object.values(modalData).map((key, value) => (
                                            <TableCell align="center">{`Rotation ${value}:  ${calculatePercentage(parseInt(key)) ?? 0}%`}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                )}
                </div>
            </div>
        </Modal>
      </div>
    );
  }
  


  export default LeadsByDate;
