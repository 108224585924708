import Constants from '../Constants/Constants';

const initialStateCheckBox = {
    tag:'',
    hours : true,
    live_calls: 'total',
    live_transfers: 'total',
    concurrent_capacity: 'total',
    calls_appetite: 'total',
    transfer_count:'total',
    revenue:'total',
    revenue_capacity:'total',
    transfer_capacity:'total',
    balance : true,
    active: true,
    availability : 'total',
    move_type : 'Long Distance',
    availableLeads : 'total',
    moveTypeAvailableTable:'long_distance',
    moveTypeUnavailableTable : 'long_distance',
    payout:true

}

const checkBox = (state=initialStateCheckBox,action={})=>{
    switch(action.type){
        case Constants.CHANGE_CHECKBOX:
            return {...state,...action.payload}
        default:
            return state
    }
}
const initialSort = {
    prop : 'none',
    order : 'descending'
}

const sort = (state=initialSort,action={})=> {
    switch (action.type) {
        case Constants.CHANGE_SORT:
            return {...state, ...action.payload}
        default:
            return state
    }
}

const initialData = {
    data : null,
    isFetching:false,
    isInLeadsTable:false
}

const data = (state=initialData,action={})=> {
    switch (action.type) {
        case Constants.CHANGE_DATA:
            return {...state, ...action.payload}
        default:
            return state
    }
}

const initialSettings = {
    HEADROOM_TARGET: -1,
    WARM_PULL_COUNT_ADJ_RATE: -1,
    RAW_PULL_COUNT_ADJ_RATE: -1
}

const settings = (state=initialSettings,action={})=> {
    switch (action.type) {
        case Constants.UPDATE_SETTINGS:
            return {...state, ...action.payload}
        default:
            return state
    }
}

const callTimeouts = (state= [],action={})=> {
    switch (action.type) {
        case Constants.UPDATE_CALL_TIMEOUTS:
            return {...state, ...action.payload}
        default:
            return state
    }
}

const initialRecentLeadsData = {
    data: null
}

const recentLeadsStatistic = (state=initialRecentLeadsData,action={})=> {
    switch (action.type) {
        case Constants.RECENT_LEADS_STATISTIC:
            return {...state, ...action.payload}
        default:
            return state
    }
}

const initialNextLeadsData = {
    data: null
}

const nextLeadsStatistic = (state=initialNextLeadsData,action={})=> {
    switch (action.type) {
        case Constants.NEXT_LEADS_STATISTIC:
            return {...state, ...action.payload}
        default:
            return state
    }
}

const Reducers = {
    checkBox,
    sort,
    data,
    callTimeouts,
    settings,
    recentLeadsStatistic,
    nextLeadsStatistic,
}

export default Reducers;