import {TableCell, TableRow} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import moment from 'moment'

const LeadsDetailsTableRow = ({leadId}) => {

        const [state, setState] = useState({
                isIntersecting: false,
                data: null
        })
        const {isIntersecting, data} = state

        const intersectionCallBack = (entries, observer) => {
            entries.forEach(async (entry) => {
                if (entry.isIntersecting) {
                    let response = await axios.get(`/buffer/leads?id=${leadId}`)
                    setState({isIntersecting: true, data: response.data})
                    observer.unobserve(entry.target)
                }
            })
        }



        const intersect = () => {
            let img = document.getElementById(`Cell ${leadId}`);
            let observer = new IntersectionObserver(intersectionCallBack)
            observer.observe(img)
        }

        useEffect(() => {
            if(data == null){
                intersect()
            }
        }, [data])

        return (
            <TableRow>
                    <TableCell id={`Cell ${leadId}`}>{leadId}</TableCell>
                    {isIntersecting ? <>
                                <TableCell>{data.from_state}</TableCell>
                                <TableCell>{data.to_state}</TableCell>
                                <TableCell>{data.source}</TableCell>
                                <TableCell>{data.priority}</TableCell>
                                <TableCell
                                    bgcolor={data.availability_qualifiers.coverage ? '#A1FF99' : '#FF9999'}>{data.availableBuyersCount} buyer{data.availableBuyersCount === 1 ? '' : 's'}</TableCell>
                                <TableCell
                                    bgcolor={data.availability_qualifiers.call_count ? '#A1FF99' : '#FF9999'}>{data.callCount}</TableCell>
                                <TableCell bgcolor={data.availability_qualifiers.dnc ? '#A1FF99' : '#FF9999'}>{}</TableCell>
                                <TableCell
                                    bgcolor={data.availability_qualifiers.followup_time ? '#A1FF99' : '#FF9999'}>{moment(data.lastPullTime || undefined).format("HH:mm:ss")}</TableCell>
                                <TableCell
                                    bgcolor={data.availability_qualifiers.local_time ? '#A1FF99' : '#FF9999'}>{moment().hour(data.localTime).format("HH:mm:ss")}</TableCell>
                                <TableCell
                                    bgcolor={data.availability_qualifiers.callback_scheduled ? '#A1FF99' : '#FF9999'}>{data.callback_time && moment(data.callback_time).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                <TableCell
                                    bgcolor={data.availability_qualifiers.sold_count ? '#A1FF99' : '#FF9999'}>{data.transfer_count}</TableCell>
                                <TableCell
                                    bgcolor={data.availability_qualifiers.move_date ? '#A1FF99' : '#FF9999'}>{moment(data.movedate).format("YYYY-MM-DD")}</TableCell>
                                <TableCell
                                    bgcolor={data.availability_qualifiers.move_region ? '#A1FF99' : '#FF9999'}>{}</TableCell>
                                <TableCell
                                    bgcolor={data.availability_qualifiers.lead_freshness ? '#A1FF99' : '#FF9999'}>{moment(data.time_created).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                        </>
                        : <><TableCell></TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell></>}


            </TableRow>
        )

}
export default LeadsDetailsTableRow;