
import React, {useEffect, useRef, useState} from "react";
import Actions from '../Actions'
import { connect } from 'react-redux';


const mapStateToProps = (state) => {
    return {
        tag:state.checkBox.tag,
        hours: state.checkBox.hours,
        live_calls: state.checkBox.live_calls,
        live_transfers: state.checkBox.live_transfers,
        concurrent_capacity: state.checkBox.concurrent_capacity,
        calls_appetite: state.checkBox.calls_appetite,
        transfer_count: state.checkBox.transfer_count,
        revenue: state.checkBox.revenue,
        revenue_capacity: state.checkBox.revenue_capacity,
        transfer_capacity:state.checkBox.transfer_capacity,
        balance: state.checkBox.balance,
        active:state.checkBox.active,
        availability: state.checkBox.availability,
        move_type: state.checkBox.move_type,
        availableLeads: state.checkBox.availableLeads,
        moveTypeUnavailableTable: state.checkBox.moveTypeUnavailableTable,
        moveTypeAvailableTable: state.checkBox.moveTypeAvailableTable,
        payout:state.checkBox.payout
    }
}

const CheckBoxCustom = (props) => {
    let {columType,headers,returnType} = props
    let first,second;

    if(props[columType] === 'total'){
        first = true;
        second = true;
    }
    else if(props[columType] === returnType[0]){
        first = true;
        second = false;
    }
    else{
        first = false;
        second = true;
    }

    const [checkedFirst,setCheckedFirst] = useState(first)
    const [checkedSecond,setCheckedSecond] = useState(second)
    const isMount = useRef(false);


    const checkIfBothChecked = (CheckedFirst,CheckedSecond,type) => {
        if (!CheckedFirst && !CheckedSecond) {
            return;
        } else {
            if (type === 'first') {
                setCheckedFirst(CheckedFirst);
            } else {
                setCheckedSecond(CheckedSecond);
            }
        }
    }


    useEffect(() => {
        let obj = {}
        if(isMount.current) {
            if (checkedFirst && checkedSecond) {
                obj[columType] = 'total'
            } else if (checkedFirst && !checkedSecond) {
                obj[columType] = returnType[0]
            } else {
                obj[columType] = returnType[1]
            }

            props.dispatch(Actions.setCheckbox(obj));

        }
        else{
            isMount.current = true
        }// eslint-disable-next-line
        },[checkedFirst,checkedSecond])

    return(

        <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <input
                id={`true`}

                type="checkbox"
                checked={checkedFirst}
                onChange={() => checkIfBothChecked(!checkedFirst,checkedSecond,'first')}
            />

            <label >
                {headers ? headers[0]: returnType[0].toString()}
             </label>

            <input
                id={`false`}

                type="checkbox"
                checked={checkedSecond}
                onChange={() => checkIfBothChecked(checkedFirst,!checkedSecond,'second')}
            />

            <label style={{marginLeft:'0.2rem'}}>
                {headers ? headers[1] :returnType[1].toString()}
            </label>
        </div>



    )
}
export default connect(mapStateToProps)(CheckBoxCustom);