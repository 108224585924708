
import React, {useEffect, useRef, useState} from "react";
import '../App.css'
import {debounce,splitArrayToGroups} from '../Helpers/helpers'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {connect} from 'react-redux'
import Actions from "../Actions";
import axios from "axios";
import {Link,useHistory} from "react-router-dom";
import TableHeeader from "./TableHeader";
import CompaniesTableCheckBoxes from "./CompaniesTableCheckBoxes";
import CompanyTableBody from "./CompanyTableBody";

const mapStateToProps = (state) => ({
    checkBoxState: {
        tag: state.checkBox.tag,
        hours: state.checkBox.hours,
        live_calls: state.checkBox.live_calls,
        live_transfers: state.checkBox.live_transfers,
        concurrent_capacity: state.checkBox.concurrent_capacity,
        calls_appetite: state.checkBox.calls_appetite,
        transfer_count: state.checkBox.transfer_count,
        revenue: state.checkBox.revenue,
        revenue_capacity: state.checkBox.revenue_capacity,
        transfer_capacity:state.checkBox.transfer_capacity,
        balance: state.checkBox.balance,
        active:state.checkBox.active,
        availability: state.checkBox.availability,
        move_type: state.checkBox.move_type,
        availableLeads: state.checkBox.availableLeads,
        payout:state.checkBox.payout
    },
    sort:{
        prop : state.sort.prop,
        order : state.sort.order
    },
    data:state.data.data
})
let styleDiv = {display:'flex',justifyContent:'center',alignItems:'center'}


const CompaniesTable = (props) =>{
    let {sort,data,checkBoxState} = props
    let {tag,
        hours,
        live_calls,
        live_transfers,
        concurrent_capacity,
        calls_appetite,
        transfer_count,
        revenue,
        revenue_capacity,
        transfer_capacity,
        balance,
        active,
        availability,
        move_type,
        availableLeads, payout} = checkBoxState
    const history = useHistory()
    let companies = data.companies.all
    const [companiesFilter,setCompanies] = useState(companies)
    const [height,setHeight] = useState(0);
    const searchBoxTag = useRef('');
    const isMounted = useRef(false);

     const camelize = (str) =>{
         return (str.replace(/[_\s]+[a-zA-Z]+/, function(word, index) {
             return word.charAt(1).toUpperCase() + word.slice(2);
         }))
     }

    const onChangeInput = (event) => {
        searchBoxTag.current = event.target.value;
        debounce(() => props.dispatch(Actions.setCheckbox({tag:searchBoxTag.current})),500)()

    }
    const getCompanyLead = async (companyID) => {

        try {
            let response = await axios.get(`/buffer/status/company?id=${companyID}`)

            history.push({
                pathname: '/companyleads',
                state:
                    {
                        leads: splitArrayToGroups(response.data.potential_leads[props.checkBoxState.availableLeads]),
                        type: props.checkBoxState.availableLeads,
                        buyer: {
                            id: response.data.id,
                            name: response.data.name,
                            counts: {
                                available: response.data.potential_leads.available.length,
                                unavailable: response.data.potential_leads.unavailable.length,
                            }
                        }
                    }
            })
        }
        catch (err){
             console.error(err)
        }
    }


    const onToggle = (toggleName) => {
        let obj = {};

        if(sort.prop!==toggleName){
            obj.prop = toggleName;
            obj.order = 'descending';
        }
        else{
            if(sort.order === 'descending') {
                obj.order = 'ascending';
            }
            else{
                obj.order = 'descending'
            }
        }
        props.dispatch(Actions.setSort(obj))
    }

    const onResize = () => {
        setHeight(document.getElementsByClassName('height')[0].clientHeight)
    }

    useEffect(()=>{
        window.addEventListener("resize", onResize)
        setHeight(document.getElementsByClassName('height')[0].clientHeight)
        return function cleanup()  {
            window.removeEventListener('resize',onResize)
        }
    },[])

    useEffect(() => {


            let filteredCompanies = companies.filter(company => {
                let isApproved = true;
                let isApproveTotal = true;

                Object.entries(company.availability_qualifiers).forEach(([entry,value])=>{
                    isApproveTotal = isApproveTotal && value
                });

                isApproved = isApproved && (checkBoxState['move_type'] === company.columns.move_type.value || checkBoxState['move_type'] === 'total')
                    Object.entries(company.columns).forEach(([property,object]) => {

                        if(object.available!==null) {
                            isApproved = isApproved && (checkBoxState[property] === object.available || checkBoxState[property] === 'total')

                        }
                        if(property==='active'){
                            isApproved = isApproved && (checkBoxState[property] === object.value || checkBoxState[property] === 'total')
                        }
                    })
                isApproved = isApproved && (checkBoxState["availability"] === isApproveTotal || checkBoxState["availability"] === 'total')

                if (searchBoxTag.current !== '') {
                    isApproved = company.name.toLowerCase().includes(searchBoxTag.current.toLowerCase()) || company.id.toString().includes(searchBoxTag.current)
                }
                return isApproved;
            })

            setCompanies(filteredCompanies)

            isMounted.current = true;


    },[
        companies,
        checkBoxState,
        tag,
        hours,
        live_calls,
        live_transfers,
        concurrent_capacity,
        calls_appetite,
        transfer_count,
        revenue,
        revenue_capacity,
        transfer_capacity,
        balance,
        active,
        availability,
        move_type,
        availableLeads,
        payout
    ])

    useEffect(() => {


            let sortedCompany;
            if(sort.prop !== 'none'){
                sortedCompany = [...companiesFilter].sort((a,b)=>{

                    if(sort.prop === 'id'||sort.prop ==='live_calls'||sort.prop ==='live_transfers'||sort.prop ==='calls_appetite'||sort.prop ==='transfer_count'||sort.prop ==='concurrent_capacity'){

                        if(sort.order === 'descending') {
                            return b.columns[sort.prop].value - a.columns[sort.prop].value
                        }
                        else{
                            return a.columns[sort.prop].value - b.columns[sort.prop].value
                        }
                    }

                    if(sort.prop === 'availableLeads'){
                        if(sort.order === 'descending') {
                            return b.potential_leads[props.checkBoxState.availableLeads] - a.potential_leads[props.checkBoxState.availableLeads]
                        }
                        else{
                            return a.potential_leads[props.checkBoxState.availableLeads] - b.potential_leads[props.checkBoxState.availableLeads]
                        }
                    }
                    if(sort.prop === 'balance'||sort.prop ==='revenue'){

                        if(sort.order === 'descending') {
                            return b.columns[sort.prop].value.replace('$', '')
                                - a.columns[sort.prop].value.replace('$', '')
                        }
                        else{
                            return a.columns[sort.prop].value.replace('$', '')
                                - b.columns[sort.prop].value.replace('$', '')
                        }
                    }

                    if(sort.prop === 'hours'||sort.prop === 'move_type'||sort.prop === 'active'){
                        if(sort.order === 'descending') {
                            if(a.columns[sort.prop].value < b.columns[sort.prop].value) { return -1; }
                            if(a.columns[sort.prop].value > b.columns[sort.prop].value) { return 1; }
                            return 0;
                        }

                        else{
                            if(b.columns[sort.prop].value < a.columns[sort.prop].value) { return -1; }
                            if(b.columns[sort.prop].value > a.columns[sort.prop].value) { return 1; }
                            return 0;
                        }
                    }
                    if(sort.prop==='name'){
                        if(sort.order === 'descending') {
                            if(a.columns[sort.prop].value[0] < b.columns[sort.prop].value[0]) { return -1; }
                            if(a.columns[sort.prop].value[0] > b.columns[sort.prop].value[0]) { return 1; }
                            return 0;
                        }

                        else{
                            if(b.columns[sort.prop].value[0] < a.columns[sort.prop].value[0]) { return -1; }
                            if(b.columns[sort.prop].value[0] > a.columns[sort.prop].value[0]) { return 1; }
                            return 0;
                        }
                    }
                    if(sort.prop === 'revenue_capacity'||sort.prop === 'transfer_capacity'){
                        let re = /.*([0-9]).*/;

                           if(a.columns[sort.prop].value === 'Unlimited'){
                                a = 10000000;
                            }
                           else{
                               a = a.columns[sort.prop].value.replace(re, '$1')
                           }
                            if(b.columns[sort.prop].value === 'Unlimited') {
                                b = 10000000;
                            }
                            else{
                                b = b.columns[sort.prop].value.replace(re, '$1')
                            }
                            if(sort.order === 'descending') {
                                return b - a
                            }
                            else{
                                return a - b
                            }


                        }

                    return 0
                })

                setCompanies(sortedCompany)
            }


        // eslint-disable-next-line
    },[sort.prop,sort.order])

    return(
        <div  style={{height: 800, width: '90%'}}>
            <Table>
                <TableBody>
                <tr>
                    <td className='noBorder'><h1>Companies</h1></td>
                </tr>
                <tr>
                    <td className='noBorder'>
                <input
                    className='search__input'
                    type="text"
                    id="formGroupExampleInput"
                    placeholder="Companies"
                    onChange={onChangeInput}
                /></td>
                </tr>
                </TableBody>
            </Table>
            <div style={{height:'600px',overflow:'auto'}}>

                <Table>
                    <TableHead>
                        <TableHeeader columns={data.companies.columns} onToggle={onToggle} camelize={camelize} styleDiv={styleDiv}/>
                        <CompaniesTableCheckBoxes columns={data.companies.columns} height={height}/>

                    </TableHead>

                    <TableBody>
                        {companiesFilter.map((item,index)=>{
                            return(
                                <TableRow key={index}>
                                    <CompanyTableBody columns={data.companies.columns} itemColumns={item.columns}/>

                                    <TableCell>
                                        <Link
                                            onClick={()=>getCompanyLead(item.id)}>{item.potential_leads.total}
                                        </Link>
                                    </TableCell>


                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>

            </div>

    )
}

export default connect(mapStateToProps)(CompaniesTable);