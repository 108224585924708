import Constants from '../Constants/Constants'

const Actions = {
    setCheckbox: (payload) => ({type:Constants.CHANGE_CHECKBOX, payload}),
    setSort: (payload) => ({type:Constants.CHANGE_SORT, payload}),
    setData: (payload) => ({type:Constants.CHANGE_DATA, payload}),
    setSettings: (payload) => ({type:Constants.UPDATE_SETTINGS, payload}),
    setCallTimeouts: (payload) => ({type:Constants.UPDATE_CALL_TIMEOUTS, payload}),
    setRecentLeadsStatistic: (payload) => ({type:Constants.RECENT_LEADS_STATISTIC, payload}),
    setNextLeadsStatistic: (payload) => ({type:Constants.NEXT_LEADS_STATISTIC, payload}),
};

export default Actions