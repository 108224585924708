import TableCell from "@material-ui/core/TableCell";
import {ReactComponent as Icon} from "../img/SVG/unfold_more.svg";
import {Tooltip} from "@material-ui/core";
import React from "react";
import TableRow from "@material-ui/core/TableRow";

const TableHeeader = ({onToggle,columns,camelize,styleDiv}) => {
    return(
        <TableRow>
        {columns.map((item,index)=>{
            return (
                <Tooltip key={index} title={<p style={{fontSize:'0.8rem'}}>{item.tooltip?item.tooltip:item.header}</p>} placement="top" arrow>
                    <TableCell className='height'>
                        <div style={styleDiv}>
                            <Icon className='icon' onClick={() => onToggle(item.key)}/>
                            <p>{item.header}</p>
                        </div>
                    </TableCell>
                </Tooltip>
            )
            })}
            <Tooltip title={<p style={{fontSize:'0.8rem'}}>Number of leads which have the potential to match this buyer</p>} placement="top" arrow>
                <TableCell>
                    <div style={styleDiv}>
                        <Icon className='icon' onClick={() => onToggle('availableLeads')}/>
                        <p>Leads Count</p>
                    </div>
                </TableCell>
            </Tooltip>
        </TableRow>
    )
}

export default TableHeeader