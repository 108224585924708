
function debounce (func, wait) {
    return function (...args) {
        clearTimeout(window.timeout)
        window.timeout = setTimeout(() => func.apply(this, args), wait)
    }
}
const splitArrayToGroups = (i_array) => {
    let newArray = []
    let array = [...i_array]
        while (array.length !== 0) {
            if (array.length > 100) {
                newArray.push(array.splice(0, 100))
            } else {
                newArray.push(array.splice(0, array.length))
            }
        }
        return newArray

}

export {
    debounce,
    splitArrayToGroups

}