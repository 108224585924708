import React, {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Body from "./Body";
import {debounce} from "../Helpers/helpers";
import LeadsDetailsTableRow from "./LeadsDetailsTableRow";
import {connect} from 'react-redux';
import Actions from '../Actions';



const LeadsDetailsTable = (props) => {
    let {dispatch, location} = props
    console.log(location)
    const buyer = location.state?.buyer
    const type = location.state?.type

    const [isBottom,setIsBottom] = useState(false)
    const [state,setState] = useState({
        leads: location.state?.leads[0],
        index:1
    })

    const handleScroll = (e) => {
        let bottom = e.target.scrollHeight - e.target.scrollTop -400 < e.target.clientHeight;
        if(bottom) {
            setIsBottom(true)
        }

        else{
            setIsBottom(false)
        }

    }
    useEffect(()=>{
        dispatch(Actions.setData({isInLeadsTable: true}))
        return () => {
            dispatch(Actions.setData({isInLeadsTable: false}))
        }
    })

    useEffect(()=>{
        if(isBottom && location.state.leads.length > state.index){
            setState({leads:[...state.leads,...location.state.leads[state.index]],index:state.index+1})
        }


    },[isBottom, location, state])
    return(
        <Body>
            <div onScroll={(event)=>debounce(handleScroll,500)(event)} style={{height:'600px',overflow:'auto', maxWidth: '80%',fontSize:'1.5rem', margin: 'auto'}} >
            {buyer && 
                <>
                    <h2> #{buyer.id} {buyer.name} | Available Leads - {buyer.counts.available} | Unavailable Leads - {buyer.counts.unavailable}</h2>
                </>
            }
            {type && <span>Showing { type == "total" ? 'all' : type } leads</span>}
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>From State</TableCell>
                    <TableCell>To State</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell>Coverage</TableCell>
                    <TableCell>Call Count</TableCell>
                    <TableCell>DNC</TableCell>
                    <TableCell>Followup Time</TableCell>
                    <TableCell>Local Time</TableCell>
                    <TableCell>Callback</TableCell>
                    <TableCell>Sold Count</TableCell>
                    <TableCell>Move Date</TableCell>
                    <TableCell>Move Region</TableCell>
                    <TableCell>Freshness</TableCell>


                </TableRow>

                </TableHead>
                <TableBody>
                    {location.state?.leads.length>0?state.leads.map((leadId,index)=>{
                        return(
                        <LeadsDetailsTableRow key={index} leadId={leadId}/>
                        )
                    }):null}
                </TableBody>

            </Table>
            </div>
        </Body>



    )
}
export default connect()(LeadsDetailsTable);