const CHANGE_CHECKBOX = 'CHANGE_CHECKBOX'
const CHANGE_SORT = 'CHANGE_SORT';
const CHANGE_DATA = 'CHANGE_DATA';
const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
const RECENT_LEADS_STATISTIC = 'RECENT_LEADS_STATISTIC';
const NEXT_LEADS_STATISTIC = 'NEXT_LEADS_STATISTIC';
const UPDATE_CALL_TIMEOUTS = 'UPDATE_CALL_TIMEOUTS';

const Constants = {
    CHANGE_SORT,
    CHANGE_DATA,
    CHANGE_CHECKBOX,
    UPDATE_SETTINGS,
    RECENT_LEADS_STATISTIC,
    NEXT_LEADS_STATISTIC,
    UPDATE_CALL_TIMEOUTS
}

export default Constants;