import Loader from "react-loader-spinner";
import React from "react";
import {connect} from 'react-redux';

const mapStateToProps = (state) => ({
    data:state.data.data
})

const Body = (props) => {

    const {children,data,isLoading} = props

    return(
        <div className='p-4' style={{width:'100%',marginBottom: 100}}>
        {data!==null && !isLoading ?
            children
        : <div style={{position:'absolute',top:'50%',right:'40%' }}>
        <p style={{marginBottom:'3rem',fontSize:'4rem'}}>Loading....</p>
        <Loader type="Circles" color="#00BFFF" height={80} width={80}/>
    </div>}
        </div>
    )



}
export default connect(mapStateToProps)(Body)