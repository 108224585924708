import TableCell from "@material-ui/core/TableCell";
import React from "react";

const CompanyTableBody = ({itemColumns,columns}) => {
    const format = (value) => {
        if (Array.isArray(value)) {
            return value.map( item => (
                <div>{item}</div>
            ));
        } else {
            return value.toString();
        }
    }
    return (
      <>
        {columns.map((item,index)=>{
            if(itemColumns[item.key].available!==null){
                return(<TableCell className={item.key} key={index} bgcolor={itemColumns[item.key].available?'#A1FF99':'#FF9999'}>{format(itemColumns[item.key].value)}</TableCell>)
            }
            else{
                return (<TableCell className={item.key} key={index}>{format(itemColumns[item.key].value)}</TableCell>)

            }
        })}
        </>

    )
}
export default CompanyTableBody;