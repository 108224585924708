import TableRow from "@material-ui/core/TableRow";
import CheckBoxCustom from "./CheckBoxCustom";
import TableCell from "@material-ui/core/TableCell";
import React from "react";



const CompaniesTableCheckBoxes = ({columns,height}) => {


    return(
        <TableRow>
            {columns.map((item,index)=>{
                if(item.key === 'id'||item.key === 'live_calls'||item.key === 'live_transfers'||item.key === 'transfer_count'||item.key === 'revenue') {
                    return (<TableCell key={index} style={{top: height}}>

                    </TableCell>)
                }
                else if(item.key === 'name'){
                    return( <TableCell key={index} style={{top: height}}>
                        <CheckBoxCustom  headers={['Available','Unavailable']} columType={'availability'} returnType={[true,false]}/>

                    </TableCell>)
                }
                else if(item.key === 'move_type'){
                    return( <TableCell key={index} style={{top:height}}>
                        <CheckBoxCustom  columType={'move_type'} returnType={['Local','Long Distance']}/>

                    </TableCell>)
                }
                else{
                    return(<TableCell key={index} style={{top:height}} >
                        <CheckBoxCustom columType={item.key} returnType={[true,false]}/>

                    </TableCell>)

                }

            })}
        </TableRow>
    )
}
export default CompaniesTableCheckBoxes;