import CompaniesTable from "./CompaniesTable";
import {connect} from 'react-redux';
import React from "react";
import Body from "./Body";
import AvailableLeadsStatisticTable from "./AvailableLeadsStatisticTable";

const mapStateToProps = (state) => {
  return {
    isFetching: state.data.isFetching,
  }
}

const Home = (props) => {
    const {isFetching} = props
    return(
        <Body isLoading={isFetching}>
            <>
                <div style={{ maxWidth: '80%',padding:'2rem',fontSize:'1.5rem',display:'flex',justifyContent:'center', margin: 'auto'}} >
                    <AvailableLeadsStatisticTable />
                </div>

                <div style={{ display:'flex' ,flexWrap:'wrap',justifyContent:'center'}}>
                    <CompaniesTable/>
                </div>
            </>
        </Body>
    )
}

export default connect(mapStateToProps)(Home)