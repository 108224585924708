
import React from "react";

const FooterLeads = () => {
    return(
        // <Card className="text-center" style={{position: "fixed", bottom: 0, height: 75, width: '100%'}} >
        //
        //     <Card.Footer>
        //         <Card.Title>Leads buffer</Card.Title>
        //     </Card.Footer>
        //
        // </Card>
        <div></div>
    )
}

export default FooterLeads;