import { connect } from "react-redux";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment/moment";
import "../Styles/AvailableLeadsPage.css";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}

const LeadsTable = props => {
    const { isFetching } = props;
    const [ queryString ] = useState(window.location.search);
    const [ leads, setLeads ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const getBgColor = field => field ? '#A1FF99' : '#FF9999';

    const refresh = async () => {
        setLoading(true);
        const url = `/buffer/statistic/available-leads/details${queryString}`;
        const leads = await axios.get(url)
            .then(({ data }) => data)
            .catch( e => {
                console.log(e);
                return [];
            });
        setLeads(leads);
        setLoading(false);
    }

    useEffect(async () => {
        await refresh();
    }, [ queryString, isFetching ]);

    return (
        <div className={"al-page"} style={{margin:'2rem 4rem', overflow:'auto'}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>From State</TableCell>
                        <TableCell>To State</TableCell>
                        <TableCell>Source</TableCell>
                        <TableCell>Coverage</TableCell>
                        <TableCell>Call Count</TableCell>
                        <TableCell>DNC</TableCell>
                        <TableCell>Next Dial Time</TableCell>
                        <TableCell>Local Time</TableCell>
                        <TableCell>Callback</TableCell>
                        <TableCell>Sold Count</TableCell>
                        <TableCell>Move Date</TableCell>
                        <TableCell>Freshness</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        loading &&
                        <TableRow>
                            <TableCell colSpan={7}></TableCell>
                            <TableCell>Loading...</TableCell>
                            <TableCell colSpan={7}></TableCell>
                        </TableRow>
                    }
                    {
                        ! leads.length && ! loading &&
                        <TableRow>
                            <TableCell colSpan={7}></TableCell>
                            <TableCell>No data</TableCell>
                            <TableCell colSpan={7}></TableCell>
                        </TableRow>
                    }
                    {
                        ! loading && leads.map( lead => (
                            <TableRow key={lead.id}>
                                <TableCell>{lead.id}</TableCell>
                                <TableCell>{lead.from_state}</TableCell>
                                <TableCell>{lead.to_state}</TableCell>
                                <TableCell>{lead.source}</TableCell>
                                <TableCell bgcolor={getBgColor(lead.availability_qualifiers.coverage)}>{lead.availableBuyersCount} buyer{lead.availableBuyersCount === 1 ? '' : 's'}</TableCell>
                                <TableCell bgcolor={getBgColor(lead.availability_qualifiers.call_count)}>{lead.callCount}</TableCell>
                                <TableCell bgcolor={getBgColor(lead.availability_qualifiers.dnc)}>{ }</TableCell>
                                <TableCell bgcolor={getBgColor(lead.availability_qualifiers.followup_time)}>{moment(lead.nextDialTime || undefined).format("HH:mm:ss")}</TableCell>
                                <TableCell bgcolor={getBgColor(lead.availability_qualifiers.local_time)}>{moment().hour(lead.localTime).format("HH:mm:ss")}</TableCell>
                                <TableCell bgcolor={getBgColor(lead.availability_qualifiers.callback_scheduled)}>{lead.callback_time && moment(lead.callback_time).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                <TableCell bgcolor={getBgColor(lead.availability_qualifiers.sold_count)}>{lead.transfer_count}</TableCell>
                                <TableCell bgcolor={getBgColor(lead.availability_qualifiers.move_date)}>{moment(lead.movedate).format("YYYY-MM-DD")}</TableCell>
                                <TableCell bgcolor={getBgColor(lead.availability_qualifiers.lead_freshness)}>{moment(lead.time_created).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </div>
    )
}

export default connect(mapStateToProps)(LeadsTable);
