import React from 'react';
import './style.comp.css'
import {ReactComponent as Icon} from '../img/SVG/refresh-svgrepo-com.svg'
import {Link} from "react-router-dom";

const HeaderLeads = (props) => {

  let {isFetching,fetchingData} = props

  return (
    <header className="header">
      <nav className="user-nav">
        <Link className='title' style={{marginLeft: '50px', fontSize: '3rem'}} to={{ pathname: '/' }}>
          Leads
        </Link>
      </nav>

      <nav className="user-nav">
        <Link className='title' style={{marginLeft: '50px', fontSize: '1.5rem'}} to={{ pathname: '/settings' }}>
          ⚙️
        </Link>
        <button className="user-nav__icon-box" disabled={isFetching} onClick={fetchingData}>
          <Icon className="user-nav__icon"/>
        </button>
      </nav>
    </header>
  );
}

export default HeaderLeads;